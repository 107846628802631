@import 'style';

.survey-container {
    background-color: $white;
}

.survey-title {
    color: $white;
}

.prescreening-btn {
    @include subtitle;
    // @include box-shadow;
    border-radius: 2px;
    background-color: $lightYellow;
    color: $darkGreen;
    min-width: 240px;
    @include transitions($fast ease-in-out);
    &:hover {
        outline:none;
        background-color: $darkGreen;
        color: $white;
        animation: scaleUp;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }
    &:active {
        outline:none;
        background-color: $lightYellow;
        color: $darkGreen;
        animation: scaleDown;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }

}

.lmh-survey-label {
    display: inline-block;
    margin-bottom: .5rem;
    width: 100%;
    cursor: pointer;
    padding: 5px;
    &:hover {
        // background: $whiteOpa6;
        border: 1px solid $darkGreen;
    }
    &.checked {
        background-color: $lightYellow;
    }
    input {
        margin-right: 10px;
    }
}

.breadcrumb-item {
    color: $midGreen;
    &:hover {
        background-color: $lightYellow;
    }
}

.restart-btn {
    &:hover {
        background-color: $lightYellow;
    }
}

.incomplete-warning
{
    font-weight: bolder;
}