@import '../../scss/style';

body {
    // min-width: 100vw;
    // min-height: 100vh;
    background-color: black;
    // background-image: url('../img/find_your_home-lg.jpg');
    background-size: cover;
    background-repeat: no-repeat; 
    background-position: center center;
}
.body-wrapper {
    background-color: $darkGreenOpa;
    width: 100%;
    height: 100%;
}
#lmh-version-indicator
{
    position: absolute;
    color: $midGreen;
    bottom: 5px;
    right: 5px;
}
.login-wrapper {
    display: flex;
    flex-wrap: wrap;
    // min-height: 85vh;
    // height: 100vh;
    min-width: 80%;
    min-height: 600px;
    width: calc( 100vw - 40px);
    height: calc( 90vh - 10px );
    // padding: 0;
    // margin: auto;
    border: 1px solid $whiteOpa8;
    background-color: transparent;
    padding: 0;
    // background-image: url('../img/find_your_home.v5-lg.jpg');
    .app-style {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        position: relative;
        height: 100%;
    }

}


.login-img-wrapper {
    background-size: cover;
    background-repeat: no-repeat; 
    background-position: center center;
    transition: $gentle;
    position: relative;
    height: 100%;       
}

.top-title {
    text-align: left;
    color: $darkGreen;
}
.title {
    @include title;
    color: $darkGreen;
    font-weight: 300;
    margin-left: 0;
}

//==================== BANNER WITH LOGO ======================
.banner {
    position: relative;
    width: 100%;
    height: 10vh;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    .banner-logo {
        height: 90%;
        margin-left: 20px;
    }
    a {
        font-family: $titleFont;
        color: $white;
        height: 10vh;
        // line-height: $font-lg;
        text-decoration: none;
        span {
            display: inline-block;
            vertical-align: middle;
            line-height: normal;
            height: 90%;
        }
    }
}



//==================== TITLE AND BUTTONS ======================
.title-wrapper {
    width: 100%;
    @include box-shadow;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    background-color: $whiteOpa8;
    .top-title {
        text-align: left;
        font-weight: 300;
        color: $darkGreen;
    }
}
.toggle-btn {
    @include subtitle;
    color: $white;
    background-color: $darkGreen;
    display: inline-flex;
    border: 1px $darkGreen solid;
    margin-left: 2px;
    justify-content: flex-end;
    align-items: center;
    transition: 0.2s ease-in-out;
    .toggle-btn-title {
        @include subtitle;
        color: $lightGreen;
        // @include text-shadow;
        font-size: $font-md;
        margin-right: $padding-lg;
        transition: 0.2s ease-in-out;
    }
    .toggle-btn-icon {
        max-height: $btn-height-sm;
        transition: 0.2s ease-in-out;
    }
    &:hover {
        background-color: $darkGreenOpa;
        @include box-shadow;
        .toggle-btn-title {
            @include subtitle;
            color: $white;
            // @include text-shadow;
            font-size: $font-md;
            margin-right: $padding-lg;
            transition: 0.2s ease-in-out;
        }
    }
    &:active {
        background-color: $darkGreenOpa;
        @include box-shadow;
        .toggle-btn-title {
            @include subtitle;
            color: $white;
            // @include text-shadow;
            font-size: $font-md;
            margin-right: $padding-lg;
            transition: 0.2s ease-in-out;
        }
        .toggle-btn-icon {
            max-height: $btn-height-sm - 5px;
        }
    }
}

.lmh-login-btn-primary {
    @include subtitle;
    // @include box-shadow;
    border-radius: 2px;
    background-color: $lightYellow;
    color: $darkGreen;
    min-width: 140px;
    @include transitions($fast ease-in-out);
    &:hover {
        outline:none;
        background-color: $darkGreen;
        color: $white;
        animation: scaleUp;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }
    &:active {
        outline:none;
        background-color: $lightYellow;
        color: $darkGreen;
        animation: scaleDown;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }
}

.about-icon
{
    min-width: 30px;
    min-height: 30px;
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: $white;
    filter: drop-shadow(black 2px 4px 6px);
    transition: .2s;
    cursor: pointer;
    &:hover, &:focus
    {
        transform: scale(1.1);
    }
}

.about-panel
{
    background-color: $white;
    border-radius: 10px;
    -webkit-box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.5); 
    box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.5);
    position: absolute;
    bottom: 3em;
    right: 10px;
    min-width: 200px;
    // width: 50%;
    &.hide
    {
        display: none;
    }
}
.about-panel-triangle
{
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15px 15px 0 15px;
    border-color: #ffffff transparent transparent transparent;
    bottom: -15px;
    right: 20px;
    position: absolute;
}

//==================== TOGGLE PANEL ======================
.toggle-panel {
    @include paragraph;
    width: 100%;
    bottom: 0;
    position: absolute;
    background-color: $whiteOpa8;
}
.about-wrapper {
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
    .welcome-msg-wrapper,
    .login-btn-wrapper,
    .login-btn-wrapper-single-btn
    {
        min-width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin: 20px 0 20px 0;
        h3 {
            @include subtitle;
            color: $darkGreen;
        }
        button {
            @include subtitle;
            @include box-shadow;
            border-radius: 2px;
            background-color: $lightYellow;
            color: $darkGreen;
            @include transitions($fast ease-in-out);
            &:hover {
                outline:none;
                background-color: $darkGreen;
                color: $white;
                animation: scaleUp;
                animation-fill-mode: forwards;
                animation-iteration-count: 1;
            }
            &:active {
                outline:none;
                background-color: $lightYellow;
                color: $darkGreen;
                animation: scaleDown;
                animation-fill-mode: forwards;
                animation-iteration-count: 1;
            }
        }
    }
    // .login-btn-wrapper-single-btn {
    //     justify-content: center;
    // }
    .login-btn-wrapper {
        justify-content: space-between;
    }
    .input-column {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin: 20px auto 20px auto;
        border: 1px solid $lightGreen;
        border-radius: 2px;
        padding: 10px 0 10px 0;
        label {
            width: 25%;
            text-align: right;
        }
        input {
            height: 100%;
            font-size: inherit;
            @include paragraph;
            border: 0px;
            min-width: calc( 70% - 40px );
            margin-left: 20px;
            @include transitions(0.2s ease-in-out);
            background-color: transparent;
            &.warning {
                background-color: $lightYellow;
            }
            &:focus {
                background-color: $white;
                outline:none;
            }
        }
    }
}


.pass-validation {
    color: darkGrey;
    // color: teal;
    // color: $darkBlue;
}
.valid {
    color: teal;
    // color: $midGreen;
}
.valid:before {
    position: relative;
    left: -2px;
    content: "✔";
}



@include media-sm {
    .about-panel
    {
        font-size: 0.8em;
        right: 0;
        padding: 10px !important;
    }
    .top-title {
        width: 100%;
    }
    .button-wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;  
    }
    .toggle-btn {
        height: $btn-height-sm;
        width: $btn-width-sm;
        .toggle-btn-title {
            font-size: $font-sm;
        }
        .toggle-btn-icon {
            height: $btn-height-sm - 5px;
            margin-left: $padding-sm;
        }
    }
    .about-wrapper {
        font-size: $font-sm;
        margin-top: $padding-sm*2;
        margin-bottom: $padding-sm*2;
        margin-left: $padding-sm;
        margin-right: $padding-sm;
    }
    .login-btn-wrapper, 
    .login-btn-wrapper-single-btn
    {
        display: flex;
        justify-content: center;
        width: 100%;
        button {
            height: $btn-height-sm;
            width: 45%;
            margin-left: auto;
            margin-right: auto;
            margin-top: 10px;
            font-size: $font-sm;
        }
    }
    .login-img-wrapper
    {
        height: 30vh !important;
    }
}

@include media-md {
    .button-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: flex-end;
        justify-content: flex-end;
    }
    .toggle-btn {
        height: $btn-height-md;
        width: $btn-width-md;
        .toggle-btn-title {
            font-size: $font-md;
        }
        .toggle-btn-icon {
            height: $btn-height-md;
            margin-left: $padding-md;
        }
    }
    .about-wrapper {
        font-size: $font-md;
        margin-top: $padding-md*2;
        margin-bottom: $padding-md*2;
        margin-left: $padding-sm;
        margin-right: $padding-sm;
    }
    .login-btn-wrapper, 
    .login-btn-wrapper-single-btn
    {
        display: flex;
        justify-content: center;
        width: 100%;
        button {
            margin: $padding-sm 0 0 0;
            @include centerEl;
            height: $btn-height-md;
            width: $btn-width-md;
            font-size: $font-sm;
        }
    }
    .login-img-wrapper
    {
        height: 30vh !important;
    }
}

@include media-lg {
    .top-title {
        width: 60%;
        
    }
    .button-wrapper {
        
        width: 40%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: flex-end;    
    }
    .toggle-btn {
        height: 100%;
        width: $btn-width-lg;
        .toggle-btn-icon {
            height: $btn-height-lg;
            margin-left: $padding-lg;
        }
    }
    .about-wrapper {
        font-size: $font-lg;
        margin-top: $padding-lg*2;
        margin-bottom: $padding-lg*2;
        margin-left: auto;
        margin-right: auto;
    }
    .login-btn-wrapper, 
    .login-btn-wrapper-single-btn
    {
        button {
            height: $btn-height-lg;
            width: $btn-width-lg;
            font-size: $font-lg;
        }
    }
}

@include media-xl {
    .top-title {
        width: 50%;
    }
    .button-wrapper {
        width: 50%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
    }
    .toggle-btn {
        height: 100%;
        width: $btn-width-lg;
        .toggle-btn-icon {
            height: $btn-height-lg;
            margin-left: $padding-lg;
        }
    }
    .about-wrapper {
        font-size: $font-lg;
        margin-top: $padding-lg*2;
        margin-bottom: $padding-lg*2;
        margin-left: auto;
        margin-right: auto;
    }
    .login-btn-wrapper,
    .login-btn-wrapper-single-btn
    {
        button {
            height: $btn-height-lg;
            width: $btn-width-lg;
            font-size: $font-lg;
        }
    }
}