@import 'style';


    //================== GLOBAL ELEMENTS ====================
.title {
    @include title;
    color: $white;
}
.hide {
    display: none;
}

p {
    @include paragraph;
}
html, body {
    // width: 100vw;
    margin: 0;
    padding: 0;
}


//============ WELCOME PAGE============

.lmh-welcome-image {
    height: 100vh;
    width: 100%;
    padding: 0;
    // margin: 0;
    // background-image: url("../img/LMH_brick_wall_watercolor3-xl.png");
    background-image: url("../img/LMH_anime_wall-xl.png");
    background-size: calc(100vh * 1.81) 100vh;
    // background-size: cover;
    background-position: center bottom;
    background-repeat: no-repeat;
    z-index: 1;
}

#home-image
{
    position: absolute;
    height: 50vh;
    // min-width: 300px;
    width: 100%;
    bottom: 0;
    padding: 0;
    margin: 0;
    background-image: url("../img/interior-xl.jpg");
    background-size: auto 100%;
    // background-size: cover;
    background-position: center bottom;
    background-repeat: no-repeat;
    // z-index: -1;
    transition: 1s;
    &:hover
    {
        transform: scale(1.1);
        // background-color: green;
        // background-size: auto 120%;
        // background: transparent;
    }
}



.lmh-title {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    top: 0;
}

#door-frame, #door-panel {
    // max-width: 260px;
    // min-width: 200px;
    // position: absolute;
    // bottom: 50px;
    // margin-left: auto;
    // margin-right: auto;
    // right: 0;
    // left: 0;
    position: absolute;
    bottom: 0;
    /* width: calc(100vw / 10); */
    margin-left: auto;
    margin-right: auto;
    right: 0;
    left: 0;
    height: calc(100vh / 1.88);
    width: calc(100vh * 1.82 / 6.5);
}
#door-panel {
    transform-origin: 95% top;
    transition: all 0.5s ease-in-out;
}
#door-group {
    cursor: pointer;
    &:hover {
        #door-panel
        {
            transform: perspective(2400px) rotateY(120deg);
        }
    }
}

// #door-panel {
    
//     /*Speed of the Door animation*/
//     transform-origin: 60% top;
//     transition: all 0.5s ease-in-out;
//     transform: perspective(1200px);
//     // transform: rotateY(-60deg);
//     // transform: perspective(1200px) rotateY(-105deg);
//     &:hover {
//         transform: rotateY(-30deg);
//     }
// }

.wrapper {
    height: 100vh;
    background-size: 100% auto;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    &.scaleUp {
        animation: zoomIn 0.5s ease-in-out;
        animation-fill-mode: forwards;
    }
}
.bg-img {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
}
.logo-wrapper {
    @include centerEl;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    min-width: 100%;
    padding: 0;
    margin: 0;
    justify-content: center;
    align-items: flex-start;
    .logo {
        margin-left: auto;
        margin-right: auto;
        margin-left: 10%;
        border-radius: 0 0 50px 50px;
        padding: 20px 10px 20px 10px;
        background-color: $darkGreenOpa;
    }
}

.memory-home-title {
    @include title;
    font-size: $font-xl * 2;
    font-weight: bolder;
    margin-top: $padding-lg;
    border-bottom: 2px solid $darkGreen;
    text-shadow:
    -4px -4px 0 $white,
     4px -4px 0 $white,
     -4px 4px 0 $white,
      4px 4px 0 $white;
}
.memory-home-subtitle {
    @include subtitle;
    font-weight: bold;
    text-shadow:
    -2px -2px 0 $white,
     2px -2px 0 $white,
     -2px 2px 0 $white,
      2px 2px 0 $white;
}
//================== BOTTOM OF THE PAGE ====================
.bottom-wrapper {
    width: 100%;
    @include centerEl;
    text-align: center;
    position: relative;
    padding: 0;
    margin: 0;
}

//================== ENTER LOG IN OPTIONS ====================
#enter-wrapper {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex-flow: column;
    bottom: 0;
    justify-content: center;
    align-content: center;
    &.hide {
        display: none;
    }
    #login-options-link {
        width: 100%;
        height: 50%;
        position: relative;
        margin: auto;
        margin-bottom: 10px;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-content: center;
    }
}
.enter {
    @include absCenterEl;
    width: 80px;
    opacity: 0.8;
    margin-bottom: 40px;
    animation: bob 2s infinite ease-in-out;
    @include transitions(0.5s ease-in-out);
}
.enter:hover {
    opacity: 1;
    animation: rotate 2s;
    @include transitions(0.5s ease-in-out);
}
.enter.fade {
    animation: fade 2s;
    animation-fill-mode: forwards;
}
#enter-notice {
    @include box-shadow;
    @include paragraph;
    background-color: #fff;
    color: #536337;
    padding: 10px;
    border-radius: 5px;
    max-width: 200px;
    position: absolute;
    top: -60;
    display: none;
    animation: fadeIn 1s ease-in-out;
    @include transitions(0.5s ease-in-out);
    &:after {
        @include text-shadow;
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(255, 255, 255, 0);
        border-top-color: #ffffff;
        border-width: 10px;
        margin-left: -10px;
    }
    &.show {
        display: block;
        bottom: 150px;
    }
}

// //================== LOGIN & REGISTER OPTIONS ====================
// .welcome-msg {
//     // @include text-shadow;
// }
// .login-option-btn {
//     @include subtitle;
//     font-weight: 300;
//     font-size: 0.9em;
// }

// #login-options-wrapper {
//     display: none;
//     animation: fadeIn 2s;
// }
// #login-options-wrapper.show {
//     @include centerEl;
//     display: flex;
//     flex-wrap: wrap;
//     @include transitions(1.5s ease-in-out);
//     justify-content: center;
//     align-items: center;
//     .login-option-item {
//         display: flex;
//         flex-wrap: wrap;
//         justify-content: space-around;
//         color: $darkGreen;
//         border: 1px solid $darkGreen;
//         background-color: $whiteOpa8;
//         font-family: $subtitleFont;
//         @include box-shadow;
//         border-radius: 5px;
//         @include transitions(0.2s ease-in-out);
//         &:hover {
//             background-color: $lightPink;
//             color: $darkGreen;
//             transform: translateY(-5px);
//         }
//         &:active {
//             transform: translateY(0px);
//         }
//     }
//     .login-option-icon {
//         max-height: 100%;
//         margin-left: 0;
//     }
// }

//================== DECEASED NAME INPUT ====================

// #deceased-name-ctn {
//     position: relative;
//     width: 100%;
// }
// .name-input {
//     height: 48px;
//     border-radius: 5px;
//     padding: 0 0 0 20px;
// }
// #confirm-name-btn {
//     position: absolute;
//     bottom: -4px;
//     width: 48px;
//     height: 48px;
//     margin-left: -53px;
//     font-size: 2.4em;
//     color: $midGreen;
//     cursor: pointer;
// }
// #confirm-name-btn:hover {
//     color: $darkGreen;
//     animation: tinyBob 1.5s ease-in-out infinite;
// }
// #return-to-option {
//     @include subtitle;
//     @include text-shadow;
//     color: #fff;
//     width: 100%;
//     margin: auto;
//     margin-top: 20px;
//     cursor: pointer;
//     &:hover {
//         @include transitions(0.5s ease-in-out);
//         transform: scale(1.1);
//     }
//     &:active {
//         transform: translateY(-4px);
//     }
// }

// =============== ERROR PAGE ====================
.error-container {
    background-color: $white;
}


@include media-sm {
    .lmh-welcome-image {
        background-image: url("../img/LMH_anime_wall-lg.png");
    }
}

@include media-md {
    .lmh-welcome-image {
        background-image: url("../img/LMH_anime_wall-lg.png");
    }
}

@include media-lg {
    .lmh-welcome-image {
        background-image: url("../img/LMH_anime_wall-lg.png");
    }
}

@include media-xl {
    .lmh-welcome-image {
        background-image: url("../img/LMH_anime_wall-xl.png");
    }
}