.mh-title {
    @include title;
}
.mh-title-white {
    @include title;
    color: $white;
}
.mh-title-dark {
    @include title;
    color: $darkGreen;
}

.mh-subtitle {
    @include subtitle;
}

.mh-subtitle-white {
    @include subtitle;
    color: $white;
}
.mh-subtitle-lightgreen {
    @include subtitle;
    color: $lightGreen;
}
.mh-subtitle-dark {
    @include subtitle;
    color: $darkGreen;
}

.mh-paragraph {
    @include paragraph;
}

.mh-paragraph-white {
    @include paragraph;
    color: $white;
}

.mh-paragraph-brown {
    @include paragraph;
    color: $darkPink;
}

.mh-paragraph-dark {
    @include paragraph;
    color: $darkGreen;
}