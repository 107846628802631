@import 'style';

$icon-width-lg: 120px;
$icon-width-md: 100px;
$icon-width-sm: 80px;

@include media-sm {
    .operation-btn-ctn {
        width: $icon-width-sm;
        height: $icon-width-sm;
        margin-left: $padding-sm;
        margin-top: $padding-sm;
        .operation-btn {
            font-size: $font-sm;
        }
    }
    .system-msg-cont {
        width: 400px;
        height: 300px;
    }
    .system-msg-close-btn{
        width: $btn-height-sm;
        height: $btn-height-sm;
    }
}

@include media-md {
    .operation-btn-ctn {
        width: $icon-width-md;
        height: $icon-width-md;
        margin-left: $padding-md;
        margin-top: $padding-md;
        .operation-btn {
            font-size: $font-md;
        }
    }
    .system-msg-cont {
        width: 400px;
        height: 300px;
    }
    .system-msg-close-btn{
        width: $btn-height-md;
        height: $btn-height-md;
    }
}

@include media-lg {
    .operation-btn-ctn {
        width: $icon-width-lg;
        height: $icon-width-lg;
        margin-left: $padding-lg;
        margin-top: $padding-lg;
        .operation-btn {
            font-size: $font-lg;
        }
    }
    .system-msg-cont {
        width: 600px;
        height: 400px;
    }
    .system-msg-close-btn{
        width: $btn-height-lg;
        height: $btn-height-lg;
    }
}

@include media-xl {
    .operation-btn-ctn {
        width: $icon-width-lg;
        height: $icon-width-lg;
        margin-left: $padding-lg;
        margin-top: $padding-lg;
        .operation-btn {
            font-size: $font-xl;
        }
    }
    .system-msg-cont {
        width: 800px;
        height: 600px;
    }
    .system-msg-close-btn{
        width: $btn-height-lg;
        height: $btn-height-lg;
    }
}