/**
* A style document for the system message panel, including media query
*/

.system-message-wrapper {
    @include transitions($gentle ease-in-out);
    &.active {
        @include animation(show $gentle normal forwards east-in-out);
    }
    @include absCenterEl;
    position: absolute;
    // width: 100vw;
    height: 100vh;
    .system-message-img-container {
        @include transitions($gentle ease-in-out);
        max-width: 800px;
        max-height: 500px;
        @include absCenterEl;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        background-color: transparent;
        @include animation(fadeIn $gentle normal forwards ease-in-out);
        .system-message-text-container {
            @include animation(fadeIn $gentle normal forwards ease-in-out);
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: column;
            // @include flex-column-even;
            // min-height: 420px;
            .system-message-title {
                @include title;
                font-weight: bolder;
                color: $darkGreen;
            }
            .system-message {
                @include subtitle;
                color: $darkPink;
                text-align: center;
            }
        }
    }
}

// ========================== MEDIA QUERIES ===============================
@include media-sm {
    .system-message-img-container {
        width: 100%;
        height: calc(100vw * 1.4);
        background-image: url('../img/message_board2_horizontal-sm.png');
        background-size: contain !important;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .system-message-text-container {
            margin: auto;
            // margin: $padding-sm $padding-sm*2 $padding-sm $padding-sm*2;
            .system-message-title {
                // font-size: $font-md;
                padding-bottom: 0;
                // font-size: 1em;
                // margin-bottom: $padding-xs;
            }
            .system-message-text {
                font-size: 1em !important;
                // margin: 0 $padding-sm 0 $padding-sm;
                // font-size: $font-sm;
            }
        }
    }
}

@include l-media-sm {
    .system-message-img-container {
        width: calc(100vh / 0.71) !important;
        height: 100% !important;
        background-image: url('../img/message_board2_horizontal-sm.png');
        background-size: contain !important;
        position: relative;
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
    }
    .system-message-text-container {
        // height: 100%;
        // width: 80vw;
        width: calc(100vh / 0.71) !important;
        // height: 100%;
        margin: auto !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        // padding: 0;
        padding: $padding-sm $padding-sm*2 $padding-sm $padding-sm*2 !important;
        .system-message-title {
            // font-size: $font-md;
            padding-bottom: 0;
            font-size: 1.2em;
            // margin-bottom: $padding-xs;
        }
        .system-message-text {
            font-size: 1.1em !important;
            // margin: 0 $padding-sm 0 $padding-sm;
            // font-size: $font-sm;
        }
    }
}

@include l-media-md {

}


@include media-md {
    .system-message-img-container {
        width: 100%;
        height: calc(100vw * 1.4);
        background-size: contain !important;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-image: url('../img/message_board2_horizontal-md.png');
        .system-message-text-container {
            margin: auto;
            // margin: $padding-sm $padding-sm*2 $padding-sm $padding-sm*2;
            .system-message-title {
                // font-size: $font-md;
                padding-bottom: 0;
                // font-size: 1em;
                // margin-bottom: $padding-xs;
            }
            .system-message-text {
                font-size: 0.8em;
                // margin: 0 $padding-sm 0 $padding-sm;
                // font-size: $font-sm;
            }
        }
    }
}
@include media-lg {
    .system-message-img-container {
        width: 100%;
        height: calc(100vw * 1.4);
        background-size: contain !important;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-image: url('../img/message_board2_horizontal-lg.png');
        .system-message-text-container {
            max-width: 700px;
            max-height: 500px;
            padding: $padding-lg $padding-lg $padding-lg $padding-lg;
            .system-message-title {
                margin-bottom: $padding-md;
            }
            .system-message {
                // margin: 0 $padding-sm 0 $padding-sm;
                font-size: $font-lg;
            }
        }
    }
}
@include media-xl {
    .system-message-img-container {
        width: 100%;
        height: calc(100vw * 1.4);
        background-size: contain !important;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-image: url('../img/message_board2_horizontal-md.png');
        .system-message-text-container {
            margin: $padding-lg $padding-lg*2 $padding-lg $padding-lg*2;
            .system-message-title {
                margin-bottom: $padding-md;
            }
            .system-message {
                // margin: 0 $padding-md 0 $padding-md;
                font-size: $font-lg;
            }
        }
    }
}