@import 'style';


.title {
    @include title;
    color: $white;
    font-weight: 300;
    margin-left: 0;
}

.lmh-carousel {
    // min-height: 85vh;
    // width: 85%;
    max-width: 100%;
    max-height: calc(100vh - 120px);
    height: calc(100vh - 120px);
}

.carousel-inner, .carousel-item
{
    height: 100%;
}

.carousel-control-prev-icon {
    width: 60px;
    height: 60px;
    background-color: $darkGreen;
    padding: 20px;
    left: 0px;
    position: absolute;
    background-size: 50%;
}

.carousel-control-next-icon {
    width: 60px;
    height: 60px;
    background-color: $darkGreen;
    padding: 20px;
    right: 0px;
    position: absolute;
    background-size: 50%;
}

.lmh-dark-btn {
    border: 1px solid $white;
    background-color: $darkGreen;
    width: 200px;
    transition: .2s;
    &:hover {
        background-color: $lightYellow;
        color: $darkGreen;
    }
}


.config-toggle-icon {
    &:hover {
        animation: tinyBob $gentle ease-in-out infinite;
    }
}

.house-root-wrapper {
    margin: 0;
    padding: 0;
    // min-width: 100vw;
    min-height: 100vh;
    background-size: contain;
    background-repeat: no-repeat; 
    background-position: center center;
    // background-color: $darkGreen;
    .body-wrapper {
        // background-color: $darkGreenOpa;
        position: relative;
        width: 100%;
        height: 90vh;
        bottom: 0;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;

//================TOGGLE WRAPPER===================
        .toggle-wrapper {
            // background-color: $darkGreenOpa;
            position: relative;
            max-width: 350px;
            width: 20%;
            height: 100%;
            bottom: 0;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            .house-instruction {
                @include paragraph;
                color: $lightGreen;
                width: 100%;
                text-align: left;
                margin: 20px 0 20px 0;
                max-height: calc( 14% - 10px );
                height: calc( 14% - 10px );
                display: flex;
                margin: 5px 0 5px 0;
                p {
                    width: 80%;
                    margin: auto;
                }
            }
            .lightbox-wrapper {
                display: flex;
                min-width: 100%;
                max-height: calc( 85% - 10px);
                width: 100%;
                height: calc( 85% - 10px);
                position: relative;
                bottom: 5px;
                overflow-y: hidden;
                // @include hideScrollBar;
                .lightbox {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-around;
                    @include hideScrollBar;
                    overflow-y: scroll;
                    white-space: nowrap;
                    position: absolute;
                    z-index: 1;
                }
            }
        }
        .login-wrapper {
            @include box-shadow;
            position: absolute;
            min-width: 70%;
            min-height: 80%;
            width: 79%;
            height: calc( 90vh - 10px );
            padding: 0;
            margin: auto;
            border: 1px solid $whiteOpa8;
            background-color: $darkGreen;
            right: 0px;
            top: 0px;
            display: flex;
            flex-wrap: wrap;
            position: relative;
            .app-style {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                position: relative;
                height: 100%;
            }
            .window-frame {
                width: 100%;
                height: 100%;
                position: absolute;
            }
        }
    }
}

// ============================ DASHBOARD PAGE ===========================


.arrow-container {
    height: 100%;
    width: 10%;
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    z-index: 99;
    .arrow {
        height: 80px;
    }
    &.left-arrow-container{
        left: 0;
        align-items: flex-start;
    }
    &.right-arrow-container {
        right:0;
        align-items: flex-end;
    }
}

.arrow-container-vertical {
    // width: calc( 100% - 40px);
    width: 99%;
    max-height: 20px;
    position: absolute;
    text-align: center;
    z-index: 99;
    .arrow {
        max-height: 20px;
        // background-color: $darkGreen;
    }
    &.up-arrow-container {
        left: 0;
        right: 0;
        top: 0;
    }
    &.down-arrow-container {
        left: 0;
        right: 0;
        bottom: 0;
    }
}

.description-wrapper {
    width: 100%;
    height: 30%;
    background: $darkGreenOpa;
    position: absolute;
    bottom: 0;
    transition: .6s;
    &.animate {
        // animation: openPanel .6s ease-in-out;
        @include animation(openPanel .6s ease-in-out);
    }
    &.hide {
        @include animation(closePanel .6s east-in-out);
    }
    .description-textbox {
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-wrap: wrap;
        flex-grow: 1;
        justify-content: center;
        align-items: center;
        height: 100%;
        p {
            @include paragraph;
            font-size: 1.2em;
            color: $whiteOpa8;
        }
    }
}


.lightbox-img-container {
    width: calc( 100% - 40px );
    height: 160px;
    border: 1px solid white;
    transition: 0.4s ease-in-out;
    margin: 10px 0 10px 0;
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    opacity: 0;
    @for $i from 1 through 5 {
        &:nth-child(#{$i}) {
            animation: 1s ease-in-out flyUp;
            animation-delay: 0.2s * $i;
        }
    }
    &:first-child {
        margin-top: 30px;
    }
    &:nth-last-child(1) {
        margin-bottom: 50px;
    }
    span {
        width: 100%;
        height: 100%;
        background-color: $darkGreenOpa;
        position: absolute;
        text-align: center;
        align-items: center;
        opacity: 0;
        transition: .6s ease-in-out;
        &.active {
            display: flex;
            &:hover {
                opacity: 1;
                p.house-desc{
                    opacity: 1;
                }
            }
        }
        p.house-desc {
            @include paragraph;
            color: $whiteOpa8;
            margin: auto;
            opacity: 0;
            transition: .6s ease-in-out;
        }
    }
    &.blur {
        opacity: 0.4;
        &:hover {
            opacity: 1;
        }
    }
    &.focus {
        opacity: 1;
    }
}

.house-activity-log {
    color: $whiteOpa8;
    @include paragraph;
}