@import '../../../../scss/style.scss';

.decorate-panel
{
    background-color: $white;
    display: flex;
    flex-direction: column;
    // min-width: 320px;
    // width: 30%;
    border-radius: 10px;
    // position: absolute;
    padding: 0;
}

.decorate-icon
{
    cursor: pointer;
    &:hover, &:focus, &:active
    {
        background-color: $darkGreen;
        color: $white;
    }
}

.operation-panel
{
    position: absolute;
    // min-width: 320px;
    // width: 30%;
    z-index: 2;
}
.about-section
{
    clear: left;
    padding-top: 10px;
}
.float-img-container
{
    float: left;
    max-width: 250px;
    margin-right: 10px;
    display: flex;
    .float-img
    {
        width: 100%;
    }
}

.complete-button
{
    min-width: 320px;
    width: 30% !important;
    background-color: #262D20;
    display: flex;
    color: #FFFFFF;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    z-index: 1;
    cursor: pointer;
    position: absolute;
    bottom: -40px;
    left: 0;
    border-bottom: 1px solid white;
    &:hover, &:active
    {
        background-color: $lightYellow;
        color: $darkGreen;
    }
}

.steps-dropdown
{
    min-width: 320px;
    width: 30% !important;
    padding: 0;
    margin: 0;
}

.memorial-reset-btn
{
    color: $lightGreen;
    position: absolute;
    bottom: -10px;
    right: 0px;
    &:hover, &:active
    {
        color: $darkGreen;
    }
}

.offering-progressbar
{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 40%;
    min-width: 320px;
    margin: auto;
}
.decorate-icon
{
    width: 60px;
    height: 60px;
}
.decorate-icon-container
{
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $midGreen;
}
.decorative-icon-container-active
{
    // background-color: $midGreen;
    color: $white;
}
@include media-sm {

}

@include l-media-sm {
    .decorate-title
    {
        display: none;
    }
    .view-container
    {
        height: 100%;
    }
    .operation-panel, .decorate-panel
    {
        max-width: 100px;
        min-width: 100px;
        height: 100%;
    }
    .decorate-icon
    {
        border-bottom: 1px solid $midGreen;
    }
    .decorate-icon-container
    {
        width: 20px;
        height: 20px;
    }

    .decorate-icon-bar
    {
        width: 100% !important;
        display: flex;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
        margin: auto;
    }
}

@include media-md {

}

@include media-lg {
    .decorate-panel
    {
        padding: 20px !important;
    }
}

@include media-xl {
    .decorate-panel
    {
        padding: 20px !important;
    }
}