@import '../../../scss/style';

.video-wrapper-bg
{
    position: fixed;
    z-index: 999;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,.8);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.4s;
    flex-direction: column;
}
.tutorial-video-iframe
{
    width: 80vw;
    height: calc(80vw * 0.56);
    min-width: 320px;
    min-height: calc(320px * 0.56);
}
.video-wrapper-close-icon
{
    width: 40px !important;
    height: 40px !important;
    cursor: pointer;
    position: absolute;
    right: 1em;
    color: white !important;
    top: 1em;
    &:hover
    {
        color: $lightYellow !important;
        transform: scale(1.1);
    }
}
.lmh-primary-btn {
    background-color: $lightYellow !important;
    color: $darkGreen !important;
    @include transitions($fast ease-in-out);
    &:hover {
        outline:none;
        background-color: $midGreen;
        animation: scaleUp;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }
    &:active {
        outline:none;
        background-color: $midGreen;
        animation: scaleDown;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }
}

.lmh-secondary-btn {
    background-color: transparent !important;
    color: $darkGreen !important;
    @include transitions($fast ease-in-out);
    &:hover {
        outline:none;
        background-color: $midGreen;
        animation: scaleUp;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }
    &:active {
        outline:none;
        background-color: $midGreen;
        animation: scaleDown;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }
}

// ============================ TOAST ==============================
.toast {
    margin-left: auto;
    margin-right: auto;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 10px;
    width: 500px;
    padding: 10px;
    background-color: $darkGreenOpa;
    border: 1px solid $white;
    border-radius: 10px;
    z-index: 999;
    color: $white;
    &.show {
        @include animation(flyUp $gentle 1);
    }
    &.fade {
        @include animation(fadeOut $gentle 1);
    }
}
.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: $white;
    // text-shadow: 0 1px 0 #fff;
    opacity: .5;
    &:hover {
        opacity: 1;
        color: $white;
    }
}

// ====================== DRAGNDROP

.drag-drop-wrapper {
    z-index: 999;
}

// CONFIRMBOX
.system-message-wrapper .system-message-img-container .system-message-text-container
{
    // margin-top: 8em;
}
.btn-group-wrapper
{
    width: 100%;
    justify-content: space-evenly;
    display: flex;
    flex-wrap: wrap;
    margin-top: 2em;
    button {
        padding: 10px;
        @include subtitle;
        @include box-shadow;
        border-radius: 2px;
        background-color: $lightYellow;
        color: $darkGreen;
        @include transitions($fast ease-in-out);
        &:hover {
            outline:none;
            background-color: $darkGreen;
            color: $white;
            animation: scaleUp;
            animation-fill-mode: forwards;
            animation-iteration-count: 1;
        }
        &:active {
            outline:none;
            background-color: $lightYellow;
            color: $darkGreen;
            animation: scaleDown;
            animation-fill-mode: forwards;
            animation-iteration-count: 1;
        }
    }
}

@include media-sm {
    .system-message-img-container
    {
        min-width: 300px;
        width: 100%;
    }
    .system-message-text-container
    {
        // margin-top: 2em;
        font-size: 0.8em;
        h3 {
            font-size: 1.2em;
        }
        .system-message
        {
            padding: 0 10px 0 10px !important;
        }
        .btn-group-wrapper
        {
            margin-top: 0.5em;
            button
            {
                max-width: 40%;
                min-width: 60px !important;
                font-size: 0.9em !important;
            }
        }
    }
    .system-message-text
    {
        font-size: 1.4em;
        // margin-top: 2em;
        // background-color: green;
    }
}

@include media-md {
}

@include media-lg {
}

@include media-xl {
}