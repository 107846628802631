@import '../../../scss/style';

.tabulator-row .tabulator-cell,
.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-col-title
{
    white-space: pre-wrap;
}

.lmh-editor
{
    background-color: $white;
}

.survey-nav-tab
{
    background-color: white;
}

.display-data-container
{
    padding: 20px;
    background-color: white;
}

.nav-item.selected-survey
{
    background-color: $lightYellow;
}

.lmh-admin-btn.unselected
{
    // background-color: #FCC87F !important;
    // color: #262D20 !important;
    transition: 0.2s ease-in-out;
    min-width: 200px;
    color: $white;
    background-color: $darkGreen;
}

.dropdown-menu.show
{
    max-height: 500px;
    overflow-y: auto;
}

.interactive-button
{
    background-color: $lightYellow;
    padding: 16;
    border-radius: 4;
    
}

#hidden-table
{
    display: none;
}