//==================== BANNER WITH LOGO ======================
.banner, 
.banner-cont 
{
    position: relative;
    width: 100%;
    height: 10vh;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    a {
        &:hover {
            color: $lightGreen;
            font-weight: 700;
            font-size: 1.1em;
            text-decoration: none;
        }
    }
    .banner-logout-cont {
        height: 100%;
        text-align: right;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        color: $lightGreen;
        @include paragraph;
        margin-right: 20px;
        .banner-logout-btn {
            width: 140px;
            font-family: inherit;
            color: $white;
            border-color: $white;
            // background-color: $white;
            font-size: 1.2em;
            border-radius: 5px;
            &:hover {
                background-color: $lightYellow;
                color: $darkGreen;
                @include animation(scaleUp $fast normal forwards ease-in-out);
            }
            &:active {
                color: $white;
                background-color: $darkGreen;
                border-color: $white;
                @include animation(clickDown $fast normal forwards ease-in-out);
            }
        }
    }

    .banner-logo {
        height: 90%;
        margin-left: 20px;
    }
    a {
        font-family: $titleFont;
        color: $white;
        height: 10vh;
        // line-height: $font-lg;
        text-decoration: none;
        span {
            @include text-shadow;
            display: inline-block;
            vertical-align: middle;
            line-height: normal;
            height: 90%;
        }
    }
}
.lmh-navbar {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    color: $white;
}
.navbar-brand.navbar-logo-cont {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    color: $white;
}
.banner-logo {
    max-height: 60px;
}
.navbar-light .navbar-toggler {
    color: white;
    border-color: white;
    background-color: white;
}
.nav-item {
    a {
        color: $white;
    }
}

@include media-sm {
    .brand-name {
        display: none;
    }
    .navbar-logo-cont {
        width: 60px;
    }
}
@include media-md {
    .brand-name {
        display: none;
    }
    .navbar-logo-cont {
        width: 60px;
    }
}
@include media-lg {
    .brand-name {
        display: flex;
    }
    .navbar-logo-cont {
        width: 280px;
    }
}
@include media-xl {
    .brand-name {
        display: flex;
    }
    .navbar-logo-cont {
        width: 280px;
    }
}