@import 'style';

@include media-sm {
    .error-container {
        margin: $padding-sm 0 $padding-sm 0;
    }
    .wrapper {
        background-image: url("../img/LMH_brick_wall_watercolor2-md.png");
    }
    #login-options-wrapper {
        width: 100%;
        position: absolute;
        bottom: 20%;
        left: 0;
        right: 0;
        .login-option-item {
            @include btn-sm;
            padding: $padding-sm;
            line-height: $btn-height-sm;
        }
    }
    .logo-wrapper {
        padding: 0 5px 10px 5px;
        display: none;
        .logo {
            width: 100px;
        }
    }
    .memory-home-title {
        font-size: $font-xl;
    }
    .name-input {
        margin-top: $padding-sm;
        width: 80%;
    }
}
@include media-md {
    .error-container {
        margin: $padding-md 0 $padding-md 0;
    }
    .wrapper {
        background-image: url("../img/LMH_brick_wall_watercolor2-md.png");
    }
    .logo-wrapper {
        display: none;
        padding: 0 5px 10px 5px;
        .logo {
            min-width: 120px;
        }
    }
    .memory-home-title {
        font-size: $font-xl * 1.5;
    }
    #login-options-wrapper {
        width: 100%;
        position: absolute;
        bottom: 20%;
        left: 0;
        right: 0;
        .login-option-item {
            @include btn-sm;
            padding: $padding-sm;
            line-height: $btn-height-sm;
            margin-top: $padding-sm;
        }
    }
    .name-input {
        margin-top: $padding-sm;
        width: 80%;
    }
}
@include media-lg {
    .error-container {
        margin: $padding-lg 0 $padding-lg 0;
    }
    .wrapper {
        background-image: url("../img/LMH_brick_wall_watercolor2-lg.png");
    }
    .logo {
        width: calc(100% / 7);
    }
    #login-options-wrapper {
        width: 80%;
        position: absolute;
        bottom: 40%;
        left: 0;
        right: 0;
        .login-option-item {
            @include btn-md;
            padding: $padding-sm;
            line-height: $btn-height-md;
            margin-top: $padding-sm;
        }
    }
    .name-input {
        margin-top: $padding-md;
        width: 50%;
    }
}
@include media-xl {
    .error-container {
        margin: $padding-lg 0 $padding-lg 0;
    }
    .wrapper {
        background-image: url("../img/LMH_brick_wall_watercolor2-lg.png");
    }
    .logo {
        width: calc(100% / 10);
    }
    #login-options-wrapper {
        width: 80%;
        position: absolute;
        bottom: 40%;
        left: 0;
        right: 0;
        .login-option-item {
            @include btn-lg;
            padding: $padding-sm;
            line-height: $btn-height-lg;
        }
    }
    .name-input {
        margin-top: $padding-lg;
        width: 50%;
    }
}