@import '../../scss/style';

.dashboard-home-wrapper
{
    position: relative; 
    width: 100%; 
    height: auto; 
    min-height: min-content;
    top: 0;
}

.house-option-box
{
    min-height: 220px !important;
    transition: .4s;
    height: fit-content !important;
}

.dashboard-home-img
{
    width: 100%;
    height: 100%;
    // position: absolute !important;
}

.house-caption-container
{
    position: absolute !important;
    width: 100%;
    height: 100%;
    top: 0;
}
.house-icon-container
{
    cursor: pointer;
    &:hover,.active
    {
        background-color: $lightYellow;
    }
}
.dashboard-house-icon
{
    width: 40px;
    height: 40px;
}

.account-manage-nav
{
    color: $midGreen !important;
    &.active
    {
        color: $darkGreen !important;
    }
}
.config-descrition-box {
    max-width: 40%;
    top: 2em;
    left: 2em;
    background-color: $white;
    transition: .4s;
    overflow: hidden;
    max-height: fit-content;
    position: absolute;
    // height: 220px;
    &.closed {
        height: 70px;
    }
}

@include media-sm {
    .house-caption-container
    {
        // position: relative !important;
        width: 100%; 
        height: 100%; 
        top: 0;
        // flex-direction: column;
    }
    .responsive-options-container
    {
        position: relative !important;
        padding: 0px !important;
    }
    .config-descrition-box.house-option-box
    {
        min-height: fit-content !important;
        padding: 0px !important;
        margin-left: 0 !important;
        width: 100%;
    }
    .dashboard-house-icon
    {
        width: 30px;
        height: 30px;
    }
    .house-icon-container
    {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        // display: flex;
        // flex-direction: row;
    }
    .dashboard-option-text
    {
        font-size: 0.8em;
        padding: 0;
        margin: 0;
        margin: auto;
        text-align: center;
    }
    .config-descrition-box
    {
        width: 100%;
        max-width: 100% !important;
        position: absolute;
        top: 0px !important;
        left: 0px !important;
        margin-left: 0px !important;
        background-color:#ffffff;
        transition: .4s;
        overflow: hidden;
        height: fit-content;
        min-height: 150px;
        // height: 220px;
        // margin-top: 10px !important;
        font-size: 0.8em;
        // padding-top: 5px !important;
        h3
        {
            font-size: 1.2em;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;
            margin: auto;
            margin-bottom: 0px;
            padding: 10px;
            text-align: center;
            width: 100%;
        }
        &.closed
        {
            padding: 0px !important;
            overflow: hidden !important;
            height: 40px !important;
            min-height: 40px !important;
        }
    }
    // .config-descrition-box.house-option-box
    // {
    //     padding-top: 1em;
    // }
    .dashboard-home-wrapper
    {
        display: flex;
        flex-direction: column-reverse;
    }
    .dashboard-home-img
    {
        position: relative !important;
    }
    .dashboard-house-icon
    {
        width: 40px;
    }
    .account-info-box.config-descrition-box.house-option-box
    {
        position: absolute !important;
        top: 100% !important;
        min-height: fit-content !important;
    }
}
@include l-media-sm {
    .responsive-options-container
    {
        position: absolute !important;

    }
    .config-descrition-box.closed
    {
        min-height: 100% !important;
    }
    .config-descrition-box.house-option-box
    {
        min-height: 100% !important;
        padding: 20px !important;
        margin-left: 0 !important;
        width: 10em !important;
    }
    .dashboard-welcome-message
    {
        padding: 0;
        text-align: center;
        margin: auto;
    }
    .dashboard-house-icon
    {
        width: 30px;
        height: 30px;
    }
    .house-icon-container
    {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;

    }
    .carousel-item.dashboard-home-img
    {
        width: 100% !important;
        margin: 0 !important;
        padding: 0 !important;
    }
    .dashboard-option-text
    {
        font-size: 0.8em;
        padding: 0;
        margin: 0;
        margin: auto;
        text-align: center;
    }
}

@include media-md {
    .house-caption-container
    {
        // position: relative !important;
        width: 100%; 
        height: 100%; 
        top: 0;
        flex-direction: column;
    }
    .config-descrition-box
    {
        width: 100%;
        max-width: 100% !important;
        position: absolute;
        top: 0px !important;
        left: 0px !important;
        margin-left: 0px !important;
        background-color:#ffffff;
        transition: .4s;
        overflow: hidden;
        height: fit-content;
        min-height: 150px;
        // height: 220px;
        // margin-top: 10px !important;
        font-size: 0.8em;
        // padding-top: 5px !important;
        h3
        {
            font-size: 1.2em;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;
            margin: auto;
            margin-bottom: 0px;
            padding: 10px;
            text-align: center;
            width: 100%;
        }
        &.closed
        {
            padding: 0px !important;
            overflow: hidden !important;
            height: 40px !important;
            min-height: 40px !important;
        }
    }
    .config-descrition-box.house-option-box
    {
        // position: relative;
        top: 0px !important;
        left: 0px !important;
        margin-left: 0px !important;
    }
}

@include p-media-md {
    .dashboard-home-wrapper
    {
        display: flex;
        flex-direction: column-reverse;
    }
    .house-caption-container
    {
        position: relative !important;
        max-height: fit-content !important;
        max-height: 10em !important;
    }
    .config-descrition-box.house-option-box
    {
        // padding: 20px !important;
        margin-left: 0 !important;
        width: 100% !important;
        min-height: 100% !important;
        max-height: 100% !important;
        padding: 10px 20px 10px 20px !important;
    }
    .dashboard-welcome-message
    {
        padding: 0;
        text-align: center;
        margin: auto;
    }
    .dashboard-house-icon
    {
        width: 30px;
        height: 30px;
    }
    .house-icon-container
    {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;

    }
    .carousel-item.dashboard-home-img
    {
        width: 100% !important;
        margin: 0 !important;
        padding: 0 !important;
    }
    .dashboard-option-text
    {
        font-size: 0.8em;
        padding: 0;
        margin: 0;
        margin: auto;
        text-align: center;
    }
}

@include l-media-sm {
    .carousel-indicators {
        position: absolute;
        right: 0;
        bottom: -35px;
        left: 0;
    }
    .carousel-item
    {
        margin-left: 40px;
        margin-right: 40px;
        width: calc(100vw - 80px);
    }


    .config-descrition-box,
    .config-descrition-box.closed
    {
        position: absolute;
        max-width: 50% !important;
        left: 0 !important;
        top: 0 !important;
        font-size: 0.8em;
        overflow-y: auto;
        padding: 10px 20px 10px 20px !important;
        min-height: 100% !important;
        // padding: 5 15px 5 15px !important;
        h3
        {
            font-size: 1em;
            margin: 0;
            padding: 5px;
            .config-toggle-icon
            {
                display: none;
            }
        }
        p
        {
            // font-size: .9em;
        }
    }
    .carousel-img
    {
        position: absolute;
        right: 0;
    }
    .carousel-caption
    {
        width: calc(100vw - 80px);
        display: flex !important;
        flex-direction: row;
        justify-content: flex-end;
        left: 0;
        right: 0;
        padding: 0;
        margin: 0;
        bottom: 0px;
        button
        {
            width: 50%;
            height: 1.5em;
            font-size: 1em;
            padding: 0;
        }
    }
    .carousel-control-prev, .carousel-control-next
    {
        max-width: 40px;
        .carousel-control-prev-icon, .carousel-control-next-icon
        {
            width: 30px;
        }
    }
    .config-descrition-box.account-info-box
    {
        width: 40% !important;
        position: absolute !important;
        left: 10em !important;
        &.closed
        {
            margin-left: 0px !important;
        }
        .lmh-login-btn-primary
        {
            padding: 5px !important;
            margin: auto !important;
        }
    }
}

@include media-lg {
    .config-descrition-box.house-option-box
    {
        position: absolute;
        // min-height: 300px !important;
        // height: 80% !important;
        width: 50%;
        // overflow: scroll !important;
        left: 2em;
        top: 2em;
        &.closed
        {
            left: 2em !important;
            top: 2em !important;
            overflow: hidden !important;
            height: 70px !important;
            min-height: 70px !important;
        }
    }
    .config-toggle-icon
    {
        display: block !important;
    }
    .dashboard-option-text
    {
        font-size: 1em !important;
    }
    .config-descrition-box.account-info-box
    {
        width: 40% !important;
        position: absolute !important;
        left: calc(40% + 2.4em) !important;
        &.closed
        {
            margin-left: 0px !important;
        }
        .lmh-login-btn-primary
        {
            padding: 5px !important;
            margin: auto !important;
        }
    }
}
@include media-xl {
    .config-descrition-box
    {
        position: absolute;
        min-height: 300px !important;
        // width: 50%;
        max-width: 500px !important;
        // overflow: scroll !important;
        left: 2em !important;
        top: 2em !important;
        &.closed
        {
            left: 2em !important;
            top: 2em !important;
            overflow: hidden !important;
            height: 70px !important;
            min-height: 70px !important;
        }
    }
    .config-descrition-box.account-info-box
    {
        width: 40% !important;
        position: absolute !important;
        left: calc(500px + 2.4em) !important;
        &.closed
        {
            margin-left: 0px !important;
        }
        .lmh-login-btn-primary
        {
            padding: 5px !important;
            margin: auto !important;
        }
    }
}