.journal-preview-panel {
    justify-content: center;
    background-color: $white;
    border-radius: 5px;
}
.journal-preview-section:not(:last-child) {
    border-right: 1px solid $darkGreen;
}
.journal-preview-header {
    // border-bottom: 1px solid $white;
}
.nav-cont {
    display: flex;
    justify-content: center;
}

.mh-pagination {
    background-color: $lightGreen;
}

.mh-page-item.page-link {
    color: $darkGreen;
    background-color: $lightGreen;
    &:hover {
        background-color: $lightYellow;
        transform: scale(1.1)
    }
}
.mh-page-item.page-link.active {
    color: $darkGreen;
    background-color: $lightYellow;
}

@include media-sm {
    .journal-preview-section:not(:last-child) {
        border-right: 0px;
    }
}
@include media-md {
    .journal-preview-section:not(:last-child) {
        border-right: 0px;
    }
}