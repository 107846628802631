@import 'style';
@import 'main-app-query';

.root-wrapper {
    margin: 0;
    padding: 0;
    // min-width: 100vw;
    min-height: 100vh;
    background-color: $white;
    background-size: contain;
    background-repeat: no-repeat; 
    background-position: center center;
    background-color: $darkGreen;
    .body-wrapper {
        background-color: $darkGreenOpa;
        position: relative;
        width: 100%;
        height: 100vh;
        bottom: 0;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .login-wrapper {
            @include box-shadow;
            position: absolute;
            min-width: 70%;
            min-height: 80%;
            width: 80%;
            height: calc( 90vh - 10px );
            padding: 0;
            margin: auto;
            border: 1px solid $whiteOpa8;
            background-color: $darkGreen;
            right: 0px;
            top: 0px;
            .app-style {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                position: relative;
                height: 100%;
            }
            .window-frame {
                width: 100%;
                height: 100%;
                background-size: auto 100%;
                background-position: center center;
                background-repeat: no-repeat;
                background-image: url('../img/window_frame-lg.png');
                position: absolute;
            }
        }
    }
}
.disable-panel-btn {
    z-index: 2;
    width: 120px;
    position: absolute;
    border: 1px solid $whiteOpa6;
    background-color: $darkGreenOpa;
    left: 40px;
    bottom: 40px;
    button {
        @include paragraph;
        font-size: 1.1em;
        color: $white;
        padding: 10px 0 10px 0;
    }
}
.reset-panel-btn {
    z-index: 2;
    width: 120px;
    position: absolute;
    border: 1px solid $whiteOpa6;
    background-color: $darkGreenOpa;
    right: 40px;
    top: 40px;
    button {
        @include paragraph;
        font-size: 1.1em;
        color: $white;
        padding: 10px 0 10px 0;
    }
}
//==================== OPERATION PANEL ======================
.top-right-about-icon
{
    top: 10px;
    right: 10px;
    z-index: 999;
}
.top-right-about-panel
{
    z-index: 999;
    top: 3em;
    right: 10px;
    width: 50em;
    min-width: 320px;
    overflow: auto;
}
// .operation-panel {
//     max-width: 20vw;
//     max-height: 89vh;
//     transition: visibility 0s linear 0.7s, opacity 0.7s ease-in-out;
//     position: absolute;
//     left: 0;
//     top: 0;
//     z-index: 1;
//     display: flex !important;
//     .operation-btn-ctn {
//         max-width: 120px;
//         max-height: 120px;
//         color: $white;
//         text-align: center;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         // background-color: $darkGreenOpa;
//         // border: 1px solid $whiteOpa6;
//         background-image: url('../img/watercolor_icon2.svg');
//         background-repeat: no-repeat;
//         background-size: contain;
//         .operation-btn {
//             font-size: 1.2em;
//             color: $white;
//             max-height: 40px;
//             @include paragraph;
//             @include text-shadow;
//             .operation-btn-thumbnail {
//                 max-width: 50%;
//                 max-height: 50%;
//                 margin-left: auto;
//                 margin-right: auto;
//                 display: block;
//                 padding: 0 0 10px 0;
//             }
//         }
//     }
// }
// .operation-panel.horizontal {
//     max-width: 85vh;
//     display: inline-block;
//     margin-left: auto;
//     margin-right: auto;
//     position: absolute;
//     left: 0;
//     right: 0;
//     .operation-btn-ctn {
//         max-width: 120px;
//         max-height: 120px;
//         color: $white;
//         text-align: center;
//         display: inline-flex;
//         // background-color: $darkGreenOpa;
//         // border: 1px solid $whiteOpa6;
//         margin-top: 0;
//         margin-left: 10px;
//         margin-right: 0;
//         margin-bottom: 10px;
//         .operation-btn {
//             font-size: 1em;
//             color: $white;
//             margin: auto;
//             max-height: 40px;
//             @include paragraph;
//             @include text-shadow;
//             word-wrap: break-word;
//             .operation-btn-thumbnail {
//                 max-width: 50%;
//                 max-height: 50%;
//                 margin-left: auto;
//                 margin-right: auto;
//                 display: block;
//                 padding: 0 0 10px 0;
//             }
//         }
//     }
// }

.operation-btn {
    min-width: 100%;
    border: 1px solid $white;
    background-color: $darkGreenOpa;
    color: $white;
    font-size: 1rem;
    max-height: 40px;
}

//==================== VIEW CONTAINER ======================
.view-container {
    border: 1px solid $whiteOpa8;
    margin-left: auto;
    margin-right: auto;
    display: block;
    position: relative;
    padding: 0;
    margin: auto;
    height: 88vh;
    .app-base 
    {
        padding-left: 0;
        padding-right: 0;
        margin-left: auto;
        margin-right: auto;
    }
}
.p5Canvas{
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

// ================== SYSTEM MESSAGE CONTAINER ===================
.system-msg-cont {
    position: absolute;
    max-width: 600px;
    max-height: 400px;
    top: 12vh;
    right: 0;
    left: 0;
    margin: auto;
    background-image: url('../img/message_board2_horizontal-md.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    transition: .4s ease-in-out;
    visibility: hidden;
    opacity: 0;
    &.show {
        visibility: visible;
        opacity: 1;
    }
    &.hide {
        visibility: hidden;
        opacity: 0;
    }
    .system-msg-wrapper {
        width: 60%;
        margin: auto;
        height: 50%;
        position: absolute;
        right: 30%;
        top: 20%;
        border-radius: 10px;
        // background-color: $whiteOpa6;
        .system-msg{
            @include paragraph;
            // color: $darkGreen;
            color: $white;
            text-shadow: 0 0 6px black;
            margin: 20px;
            font-size: 1.3em;
        }
    }
    .system-msg-close-btn-wrapper {
        position: relative;
        // margin-top: 24%;
        .system-msg-close-btn {
            background-color: $whiteOpa6;
            border: 1px solid $darkGreenOpa;
            position: absolute;
            right: 22%;
            margin-top: 9%;
            transition: 0.4s;
            &:hover {
                animation: 0.4s scaleUp forwards;
                background-color: $lightGreenOpa;
            }
            &:active {
                animation: .4s scaleDown forwards;
                background-color: $lightYellow;
            }
        }
    }
}

// =========================== DRAG ADN DROP AREA ============================
.drag-drop-wrapper {
    @include absCenterEl;
    min-width: 500px;
    min-height: 380px;
    background-color: $whiteOpa8;
    width: 40%;
    height: 40%;
    border-radius: 5px;
    transition: $gentle;
    display: none;
    &.focus {
        animation: fadeIn $gentle ease-in-out forwards;
        @include flexCenter;
    }
    &.hide {
        @include flexCenter;
        animation: fadeOut $gentle ease-in-out forwards;
    }
    .close-btn-ctn {
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
        img {
            width: $btn-height-lg;
            height: $btn-height-lg;
        }
    }
    .image-form {
        width: calc(100% - 40px);
        height: calc(100% - 40px);
        @include flexCenter;
        .drag-drop-title {
            @include flexCenter;
            min-height: 120px;
            width: 100%;
            height: 20%;
            // background-color: $darkPink;
            .drag-drop-file-input {
                display: none;
            }
            h3 {
                @include title;
                width: 100%;
                color: $darkGreen;
            }
        }
        .drag-drop-block {
            @include flexCenter;
            background-color: $lightGreen;
            width: 100%;
            min-height: 140px;
            height: 40%;
            border-radius: 5px;
            border: 2px dashed $darkPink;
            transition: $gentle;
            padding: 10px 20px 10px 20px;
            line-height: 2em;
            input {
                width: 0.1px;
                height: 0.1px;
                opacity: 0;
                overflow: hidden;
                position: absolute;
                z-index: -1;
            }
            &:hover {
                border: 2px dashed $darkGreen;
                background-color: $midGreen;
                color: $whiteOpa8;
                font-size: inherit;
                animation: scaleUp $gentle ease-in-out forward;
                h3 {
                    color: inherit;
                    font-size: inherit;
                }
            }
            &.is-dragover {
                border: 2px dashed $darkGreen;
                background-color: $midGreen;
                color: $whiteOpa8;
                font-size: inherit;
                animation: scaleUp $gentle ease-in-out forward;
                h3 {
                    color: inherit;
                    font-size: inherit;
                }
            }
            &.is-uploading {
                // border: 2px dashed $darkGreen;
                // background-color: $midGreen;
                // color: $whiteOpa8;
                // font-size: inherit;
                // animation: scaleUp $gentle ease-in-out forward;
                // h3 {
                //     color: inherit;
                //     font-size: inherit;
                // }
            }
            &.uploading {

            }
            h3 {
                @include title;
                width: 100%;
                color: $darkGreen;
            }
        }
    }

}
.advancedUpload {
    display: none;
}
.notAdvancedUpload {
    display: block;
}


// =================== STEP WRAPPER ==================
.step-wrapper-container
{
    min-width: 300px;
    min-height: 120px;
    background-color: $darkGreenOpa;
    border: 1px solid $white;

}

// .steps-dropdown
// {
//     min-width: 220px;
//     background-color: $darkGreenOpa;
//     color: $white;
//     border: 1px solid $white;
// }

.dropdown-toggle
{
    min-width: 220px;
    background-color: $darkGreenOpa;
    color: $white;
    border: 1px solid $white;
    &:hover {
        background-color: $lightGreen;
        color: $darkGreen;
    }
    &:active, &:focus, &:checked {
        background-color: $lightGreen;
        color: $darkGreen;
    }
}

@include media-sm {
    .lmh-primary-btn
    {
        font-size: 0.6em;
        min-width: 120px !important;
    }
    .journal-activity-title
    {
        font-size: 0.8em !important;
    }
}

@include media-md {
}

@include media-lg {
}

@include media-xl {
}