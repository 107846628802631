/**
* Here I define the color palettes
*/
$lightGreen: #B6BA9F;
$lightGreenOpa: rgba(182, 186, 159, .8);
$midGreen: #536337;
$darkGreen: #262D20;
$darkGreenOpa: rgba(38, 45, 32, .8);
$lightPink: #fdc5c4;
$midPink: #fb6a65;
$darkPink: #644857;
$darkPinkOpa: rgba(100,72,87, 0.8);
$lighterYellow: #f7d5a6;
$lightYellow: #FCC87F;
$lightYellowOpa4: rgba(252, 200, 127, 0.4);
$lightYellowOpa2: rgba(252, 200, 127, 0.2);
$darkBlue: #003366;
$white: #FFFFFF;
$whiteOpa8: rgba(255,255,255, .8);
$whiteOpa6: rgba(255,255,255, .6);
$whiteOpa4: rgba(255,255,255, .4);
$whiteOpa2: rgba(255,255,255, .2);
$lightGrey: #f8f8f8;

/**
 *  Predefined class in color
 */
.bg-white {
    background-color: $white;
}
.bg-dark-pink {
    background-color: $darkPink;
}
.bg-light-pink {
    background-color: $lightPink;
}
.bg-dark-green {
    background-color: $darkGreen;
}
.bg-light-green {
    background-color: $lightGreen;
}

/**
* Here I define the font pairs
*/
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500|PT+Sans:400,700');
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed');
// @import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400;700&display=swap'); //Caveat
@import url('https://fonts.googleapis.com/css2?family=Delius&display=swap'); //Delius
// @import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@600;700&display=swap'); //Dancing Script
// @import url('https://fonts.googleapis.com/css?family=Caveat:400,700');
// @import url('https://fonts.googleapis.com/css?family=Patrick+Hand');
// @import url('https://fonts.googleapis.com/css?family=Handlee');
// @import url('https://fonts.googleapis.com/css?family=Reenie+Beanie');
// @import url('https://fonts.googleapis.com/css?family=Sacramento');
// @import url('https://fonts.googleapis.com/css?family=Bad+Script');
$titleFont: 'Montserrat', sans-serif;
$subtitleFont: 'PT Sans', sans-serif;
$paragraphFont: 'PT Sans', sans-serif;
$diaryFont: 'Delius', cursive;
// $background-pattern: url('../img/bg_pattern-03.svg');

.background-pattern {
    // width: 100%;
    // height: 100%;
    // background-image: $background-pattern;
    background-repeat: repeat;
    background-size: 5%;
    background-color: $darkGreen;
    background-blend-mode: soft-light;
}
/* Generated by Glyphter (http://www.glyphter.com) on  Mon Aug 13 2018*/
@font-face {
    font-family: 'Glyphter';
    src: url('../fonts/Glyphter.eot');
    src: url('../fonts/Glyphter.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Glyphter.woff') format('woff'),
         url('../fonts/Glyphter.ttf') format('truetype'),
         url('../fonts/Glyphter.svg#Glyphter') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class*='icon-']:before{
	display: inline-block;
   font-family: 'Glyphter';
   font-style: normal;
   font-weight: normal;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale
}
[class*='icon-2-']:after{
	display: inline-block;
   font-family: 'Glyphter';
   font-style: normal;
   font-weight: normal;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale
}
.icon-2-enter_symbol:after {content:'\0041';}
.icon-enter_symbol:before{content:'\0041';}
.icon-back_btn:before{content:'\0042';}
/*
* * Define the padding
*/
$padding-sm: 10px;
$padding-md: 25px;
$padding-lg: 40px;

/**
* Define the height of button
*/
$btn-height-sm: 30px;
$btn-height-md: 35px;
$btn-height-lg: 40px;

/**
* Define the width of button
*/
$btn-width-sm: 200px;
$btn-width-md: 220px;
$btn-width-lg: 240px;

/**
* Define second type of button
*/
$btn2-width-sm: 40px;
$btn2-width-md: 50px;
$btn2-width-lg: 60px;
$btn2-width-xl: 70px;


/**
* Define sizes of btn
*/
@mixin btn-lg {
    height: $btn-height-lg;
    width: $btn-width-lg;
}
@mixin btn-md {
    height: $btn-height-md;
    width: $btn-width-md;
}
@mixin btn-sm {
    height: $btn-height-sm;
    width: $btn-width-sm;
}


/**
*   Style the basic dom selector
*/
*, html, body {
    // margin: 0;
    // padding: 0;
    // background-color: $darkGreen;
}
a {
    text-decoration: none;
}
input:focus, textarea {
    outline: none !important;
}
button {
    background-color: transparent;
    border: 0;
    cursor: pointer;
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid black;
    width: 100%;
}


@mixin absCenterEl {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

@mixin centerEl {
    margin-left: auto;
    margin-right: auto;
}

@mixin flexCenter {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

@mixin hideScrollBar {
    // hide the scrollbar
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    /* width */
    &::-webkit-scrollbar {
        width: 0px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
        background: transparent; 
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: transparent; 
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: transparent; 
    }
}

/** 
* Define the font sizes
*/
$font-xl: 24px;
$font-lg: 20px;
$font-md: 16px;
$font-sm: 12px;

@mixin title {
    font-family: $titleFont;
    font-weight: 500;
    @include centerEl;
}

@mixin subtitle {
    font-family: $subtitleFont;
    font-weight: 400;
}

@mixin paragraph {
    font-family: $paragraphFont;
    font-weight: 300;
}

@mixin flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin flex-column-even {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: space-around;
}

@mixin media-sm {
    @media only screen and (max-width: 375px) {
        @content;
    }
}

@mixin media-md {
    @media only screen and (max-width: 720px) {
        @content;
    }
}

@mixin p-media-md {
    @media only screen and (max-width: 720px) 
    and (orientation: portrait) {
        @content;
    }
}


@mixin media-lg {
    @media only screen and (min-width: 721px) {
        @content;
    }
}

@mixin media-xl {
    @media only screen and (min-width: 1025px) {
        @content;
    }
}


/* iPhone Landscape */
@mixin l-media-sm
{
    @media only screen 
    and (max-width: 991px)
    and (orientation: landscape) { 
        @content;
  }
}
/* iPad Landscape */
@mixin l-media-md {

    @media only screen 
    and (min-width: 992px) 
    and (orientation: landscape) { 
        @content;
    }
}

// @mixin l-media-lg
// {
//     @media only screen 
//     and (min-device-width: 768px) 
//     and (max-device-width: 1024px) 
//     and (orientation: landscape) 
//     and (-webkit-min-device-pixel-ratio: 2) {
//         @content;
//   }
// }

@mixin box-shadow {
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
}

@mixin bottombox-shadow {
    -webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
}


@mixin text-shadow {
    text-shadow: 0px 0px 10px rgba(0,0,0,0.75);
}

@mixin transitions($params) {
    -webkit-transition: $params;
    -moz-transition: $params;
    -o-transition: $params;
    transition: $params;
}

@mixin animation($animations) {
    -webkit-animation: $animations;
    -moz-animation:    $animations;
    -o-animation:      $animations;
    animation:         $animations;
}
// ================== STYLE SCROLLBAR ================

html {
    --scrollbarBG: transparent; // #CFD8DC;
    --thumbBG: #90A4AE;
  }
  body::-webkit-scrollbar {
    width: 11px;
  }
  body {
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  }
  body::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }
  body::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG) ;
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
  }
  
//========== OTHER USEFUL CLASSES =========

.vertical-align {
    display: inline-block;
    vertical-align: middle;
}
.white-title {
    @include title;
    color: $white;
}
.white-subtitle {
    @include subtitle;
    color: $white;
}
.cursor-pointer {
    cursor: pointer;
}
.white-font {
    @include paragraph;
    color: $white;
}
.text-link {
    transition: 0.4s;
    &:hover {
        font-size: 1.1em;
    }
    &:active, &:focus {
        font-size: 0.95em;
    }
}
.row.row-eq-height {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
}
.row.row-eq-height > [class*='col-'] {
    display: flex;
    flex-direction: column;
}


.skeleton-loading {
    position: relative;
    background-color: #E2E2E2;

    &.card-image {
        border-radius: 0;
    }

    &::after {
        display: block;
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        transform: translateX(-10%);
        background: linear-gradient(90deg, transparent, rgba(255, 255, 255, .8), transparent);
        // mix-blend-mode: screen;
        animation: loading 1.5s infinite;
    }
}

// @keyframes loading {
//     100% {
//         transform: translateX(100%);
//     }
// }