//==================== COMPONENTS STYLES ==================== 
.rect-btn-plain-ctn {
    display: flex;
    justify-content: center;
    .rect-btn-plain {
        min-width: 120px !important;
        max-width: 200px;
        border: $white 1px solid;
        border-radius: 3px;
        text-align: center;
        @include paragraph;
        padding: 10px 5px 10px 5px;
        font-family: inherit;
        color: $white;
        background-color: $darkGreen;
        &:hover {
            background-color: $lightYellow;
            color: $darkGreen;
            @include animation(scaleUp $fast normal forwards ease-in-out);
        }
        &:active {
            color: $white;
            background-color: $darkGreen;
            border-color: $white;
            @include animation(clickDown $fast normal forwards ease-in-out);
        }
    }
}

.icon-rect-btn-plain-ctn {
    display: flex;
    justify-content: center;
    background-image: url('../img/rect-btn-watercolor-2.svg');
    background-repeat: no-repeat;
    background-size: contain;
    .icon-rect-btn-plain {
        min-width: 60px;
        max-width: 200px;
        text-align: left;
        margin-left: 10px;
        @include paragraph;
        padding: 10px 5px 10px 5px;
        font-family: inherit;
        color: $white;
        text-shadow: 1px 1px 10px $darkGreen;
    }
    &:hover {
        @include animation(scaleUp $fast normal forwards ease-in-out);
    }
    &:active {
        @include animation(clickDown $fast normal forwards ease-in-out);
    }
}



// =============== ELEMENTS ACROSS PAGES ===============
.btn-plain-ctn {
    max-width: 120px;
    max-height: 120px;
    background-color: $darkGreenOpa;
    color: $white;
    text-align: center;
    display: inline-flex;
    border: 1px solid $whiteOpa6;
    margin-top: 0;
    margin-left: 10px;
    margin-right: 0;
    margin-bottom: 10px;
    @include text-shadow;
    .btn-plain {
        width: 100%;
        font-size: 1.2em;
        color: $white;
        margin: auto;
        @include paragraph;
        @include text-shadow;
        word-wrap: break-word;
        .btn-plain-thumbnail {
            max-width: 50%;
            max-height: 50%;
            margin-left: auto;
            margin-right: auto;
            display: block;
            padding: 0 0 10px 0;
        }
    }
}
.operation-btn-ctn {
    max-width: 100px;
    max-height: 100px;
    color: $white;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: $darkGreenOpa;
    // border: 1px solid $whiteOpa6;
    background-image: url('../img/watercolor_icon2.svg');
    background-repeat: no-repeat;
    background-size: contain;
    .operation-btn {
        font-size: 1.1em;
        color: $white;
        @include paragraph;
        @include text-shadow;
        .operation-btn-thumbnail {
            max-width: 50%;
            max-height: 50%;
            margin-left: auto;
            margin-right: auto;
            display: block;
            padding: 0 0 0 0;
        }
        .operation-btn-img {
            max-width: 50%;
            max-height: 50%;
            margin-left: auto;
            margin-right: auto;
            display: block;
            padding: 0 0 0 0;
        }
    }
}


// =============================== LOGIN PAGE BUTTON ================================
.login-btn-wrapper,
.login-btn-wrapper-single-btn
{
    min-width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 20px 0 20px 0;
    h3 {
        @include subtitle;
        color: $darkGreen;
    }
    button {
        @include subtitle;
        @include box-shadow;
        border-radius: 2px;
        background-color: $whiteOpa2;
        color: $darkGreen;
        @include transitions($fast ease-in-out);
        &:hover {
            outline:none;
            background-color: $lightYellow;
            animation: scaleUp;
            animation-fill-mode: forwards;
            animation-iteration-count: 1;
        }
        &:active {
            outline:none;
            background-color: $lightYellow;
            animation: scaleDown;
            animation-fill-mode: forwards;
            animation-iteration-count: 1;
        }
    }
}
.login-btn-wrapper {
    justify-content: space-evenly;
    position: absolute;
    bottom: 40px;
}

.lmh-primary-btn {
    background-color: $lightYellow !important;
    color: $darkGreen !important;
    @include transitions($fast ease-in-out);
    min-width: 200px;
    &:hover {
        outline:none;
        background-color: $midGreen;
        animation: scaleUp;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }
    &:active {
        outline:none;
        background-color: $midGreen;
        animation: scaleDown;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }
}

.lmh-secondary-btn {
    background-color: transparent !important;
    color: $darkGreen !important;
    @include transitions($fast ease-in-out);
    min-width: 200px;
    &:hover {
        outline:none;
        background-color: $midGreen;
        animation: scaleUp;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }
    &:active {
        outline:none;
        background-color: $midGreen;
        animation: scaleDown;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }
}