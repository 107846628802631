@import 'style';

@include media-sm {
    .top-title {
        width: 100%;
    }
    .button-wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;  
    }
    .toggle-btn {
        height: $btn-height-sm;
        width: $btn-width-sm;
        .toggle-btn-title {
            font-size: $font-sm;
        }
        .toggle-btn-icon {
            height: $btn-height-sm - 5px;
        }
    }
    .about-wrapper {
        font-size: $font-sm;
        margin-top: $padding-sm*2;
        margin-bottom: $padding-sm*2;
        margin-left: $padding-sm;
        margin-right: $padding-sm;
    }
    .login-btn-wrapper {
        button {
            height: $btn-height-sm;
            width: 45%;
            margin-left: auto;
            margin-right: auto;
            margin-top: 10px;
        }
    }
}

@include media-md {
    .button-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: flex-end;
        justify-content: flex-end;
    }
    .toggle-btn {
        height: $btn-height-md;
        width: $btn-width-md;
        .toggle-btn-title {
            font-size: $font-sm;
        }
        .toggle-btn-icon {
            height: $btn-height-md;
        }
    }
    .about-wrapper {
        font-size: $font-sm;
        margin-top: $padding-md*2;
        margin-bottom: $padding-md*2;
        margin-left: $padding-sm;
        margin-right: $padding-sm;
    }
    .login-btn-wrapper {
        button {
            height: $btn-height-md;
            width: $btn-width-md;
        }
    }
}

@include media-lg {
    .top-title {
        width: 60%;
    }
    .button-wrapper {
        width: 40%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: flex-end;    
    }
    .toggle-btn {
        height: $btn-height-lg;
        width: $btn-width-lg;
        .toggle-btn-icon {
            height: $btn-height-lg;
        }
    }
    .about-wrapper {
        font-size: $font-md;
        margin-top: $padding-lg*2;
        margin-bottom: $padding-lg*2;
        margin-left: auto;
        margin-right: auto;
    }
    .login-btn-wrapper {
        button {
            height: $btn-height-lg;
            width: $btn-width-lg;
        }
    }
}

@include media-xl {
    .top-title {
        width: 50%;
    }
    .button-wrapper {
        width: 50%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
    }
    .toggle-btn {
        height: $btn-height-lg;
        width: $btn-width-lg;
        .toggle-btn-icon {
            height: $btn-height-lg;
        }
    }
}