@import '../../scss/style';

$radius: 20px;
/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
  }

.diaryFont {
    font-family: $diaryFont;
    color: $darkBlue;
    font-weight: bolder;
    text-align: left;
}
/* Hide the browser's default checkbox */
.section-checkbox-label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
.section-checkbox-wrapper
{
    border: 1px solid $darkGreen;
}
.section-checkbox-label
{
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 0.8em;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.journal-textarea-save-icon
{
    width: 25px;
    height: 25px;
    top: 0;
    right: 1.5em;
    position: absolute;
    color: $lightGreen;
    fill: $midGreen;
    transition: 0.2s;
    cursor: pointer;
    &:hover {
        transform: scale(1.1);
        color: $midPink;
        fill: $midPink;
    }
}

/* Create a custom checkbox */
.section-checkmark {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 5px;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border: 1px solid $darkGreen;
    margin-top: auto;
    margin-bottom: auto;
}

.lmh-primary-btn-disabled
{
    background-color: #c8c8c8;
    color: #888888;
}


/* On mouse-over, add a grey background color */
.section-checkbox-label:hover input ~ .section-checkmark {
    background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .section-checkbox-label input:checked ~ .section-checkmark {
    background-color: $darkGreen;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .section-checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .section-checkbox-label input:checked ~ .section-checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .section-checkbox-label .section-checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

//================== ROOT WRAPPER ================== 
$journalQuestionHeight: 60px;
.diary-root-wrapper {
    margin: 0;
    padding: 0;
    // max-width: 100vw;
    // max-height: 100vh;
    background-color: $darkGreen;
    position: relative;

//================== DIARY WRAPPER ===================
    .diary-wrapper{
        @include centerEl;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
        background-color: $darkGreen;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        bottom: 0;
        z-index: 1;
        border-radius: 40px;
        font-size: 1.4em;

//================== JOURNAL PROMPTS AREA ===================
        .journal-prompts-panel {
            right: 0;
            // margin-top: 55.3771px;
            // max-width: 50%;
            // padding: 40px;
            margin: 0;
            // min-height: 100%;
            // background: rgb(216,216,216);
            background: linear-gradient(90deg, rgba(216,216,216,1) 0%, rgba(255,255,255,1) 10%);
            max-width: 100%;
            padding: 10px;
            // border-top-right-radius: 40px;
            // border-bottom-right-radius: 40px;
            overflow-y: auto;
            overflow: overlay;
            scrollbar-width: thin;
            scrollbar-color: transparent var(--scrollbarBG);
            mix-blend-mode: multiply;
            &::-webkit-scrollbar {
                width: 11px;
            }
            &::-webkit-scrollbar-track {
                background: transparent;
            }
            &::-webkit-scrollbar-thumb {
                background-color: var(--thumbBG) ;
                border-radius: 6px;
                border: 3px solid var(--scrollbarBG);
            }
              
            .journal-prompts-control {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
                height: $btn2-width-lg;
                p{
                    line-height: 48px;
                    svg {
                        width: 48px;
                        height: 48px;
                        fill: $lightYellow;
                        transition: $gentle;
                        &.hover {
                            fill: $midGreen;
                            @include animation(pop $gentle 1);
                        }
                        &.active {
                            fill: $midPink;
                        }
                    }
                }
            }
            .img-btn-plain-ctn, .img-btn-plain {
                width: $btn2-width-lg;
                height: $btn2-width-lg;
                transition: $gentle;
                &.left-arrow {
                    &:hover {
                        @include animation(leftBounce $gentle infinite);
                    }
                }
                &.right-arrow {
                    display: flex;
                    justify-content: flex-end;
                    &:hover {
                        @include animation(rightBounce $gentle infinite);
                    }
                }
                &:active {

                }
            }
            .journal-section {
                transition: all $gentle;
                margin: 0;
                padding: 0;
                height: auto !important;
                &.active {
                    @include animation(increaseHeight $gentle forwards ease-in-out);
                    // background-color: $lightPink;
                }
                &.blur {
                    @include animation(reduceHeight $gentle forwards ease-in-out);
                }
                .journal-prompts-question-ctn {
                    width: 100%;
                    height: $journalQuestionHeight;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    position: relative;
                    // background-color: $darkGreen;
                    cursor: pointer;
                    &.mandatory {
                        background-color: $lightGreen;
                    }
                    &.optional {
                        background-color: $lightYellow;
                    }
                    svg {
                        width: 30px;
                        height: 30px;
                        fill: $lightGreen;
                        transition: $gentle;
                        &.hover {
                            fill: $midGreen;
                            @include animation(pop $gentle 1);
                        }
                        &.active {
                            fill: $midPink;
                        }
                    }
                    .journal-prompts-question {
                        font-family: $diaryFont;
                        color: $darkBlue;
                        font-weight: inherit;
                        text-align: left;
                    }
                }
                .journal-prompts-entry-ctn {
                    width: 100%;
                    height: 0;
                    transition: $gentle;
                    &.active {
                        background-color: $lightYellowOpa2;
                        border-top: $lightYellow 2px solid;
                        border-bottom: $lightYellow 2px solid;
                        height: 260px;
                        textarea {
                            visibility: visible;
                        }
                    }
                    textarea {
                        transition: $gentle;
                        visibility: hidden;
                        margin: 20px 20px 20px 20px;
                        font-family: $diaryFont;
                        color: $darkPink;
                        border: 0px;
                        background: transparent;
                        width: calc(100% - 40px);
                        height: 220px;
                        line-height: 200%;
                        font-weight: inherit;
                    }
                }
            }
 
        }
//================== JOURNAL ACTIVITIES AREA ===================
        .journal-activity-panel {
            margin: 0px;
            // padding: 40px;
            // border-top-left-radius: 40px;
            // border-bottom-left-radius: 40px;
            position: absolute;
            color: $darkPink;
            font-family: $diaryFont;
            font-weight: bolder;
            // border-radius: 40px;
            // max-width: 50%;
            // max-height: 100%;
            // min-height: 100%;
            mix-blend-mode: multiply;
            max-width: 100%;
            overflow-y: auto;
            .journal-activity-title {
                @include centerEl;
                width: 100%;
                color: inherit;
                margin-bottom: 20px;
                text-align: left;
                font-family: inherit;
                font-size: inherit;
                font-weight: inherit;
                .journal-activity-customize-title {
                    font-family: inherit;
                    font-size: inherit;
                    font-weight: inherit;
                    width: 100%;
                    border: 0px transparent;
                    color: inherit;
                    background-color: transparent;
                    margin-left: 20px;
                }
            }
            .journal-screen-shot {
                width: 100%;
            }
            .journal-activity-list {
                @include centerEl;
                color: inherit;
                text-align: left;
                font-family: inherit;
                font-size: inherit;
                font-weight: inherit;
            }
        }
    }
//================== DISPLAY INFORMATION ABOUT THE DIARY ===================
    .information-panel-wrapper {
        position: absolute;
        margin: auto;
        right: 0;
        min-width: 220px;
        max-width: 350px;
        min-height: 500px;
        height: 50%;
        background-image: url("../../img/message_board2-md.png");
        background-size: cover;
        background-repeat: no-repeat;
        // background-color: $darkGreenOpa;
        // border: 1px solid $whiteOpa6;
        z-index: 4;
        .information-title {
            @include title;
            color: $darkGreen;
            margin-top: 20px;
        }
        .information-text {
            margin: 20px 35px 20px 35px;
            color: $darkGreen;
            @include paragraph;
            text-align: justify;
        }
    }
}
.select-date-section
{
    display: flex;
    max-width: calc(50% - 60px);
    width: 50%;
    justify-content: space-between;
    font-size: 0.8em;
}
.journal-activity-title-container
{
    width: 50%;
    /* max-width: 100vw; */
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
    // margin-top: 20px;
}

// .journal-textarea-save-icon
// {
//     position: absolute;
//     right: 0;
//     top: 0.1em;
//     color: $lightGreen;
//     fill: $midGreen;
//     transition: 0.2s;
//     cursor: pointer;
//     &:hover {
//         transform: scale(1.1);
//         color: $midPink;
//         fill: $midPink;
//     }
// }

.imagined-dialogue-extra
{
    // display: none;
    cursor: pointer;
    &:hover
    {
        background-color: $darkGreenOpa;
        color: $white;
    }
    .journal-textarea-save-icon,
    textarea
    {
        display: none;
    }
    .imagined-dialogue-question
    {
        color: $lightGreen;
    }
}

.writing-subject-select {
    width: calc(100% - 40px);
    border: 1px $darkGreenOpa solid;
    background-color: transparent;
    color: $darkPink;
    font-family: $diaryFont;
    // border-radius: 5px;
}
.narrative-notes-tag
{
    position: absolute;
    top: -0.5em;
    background: $darkPink;
    color: white;
    font-size: 0.7em;
    padding-left: 0.4em;
    padding-right: 0.4em;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.writing-type-btn {
    min-width: 60px;
    color: $darkPink;
    font-family: $diaryFont;
    border: 1px solid $darkPink;
    border-radius: 2px;
    margin-left: 5px;
    &:hover {
        background-color: $darkPink;
        color: $white;
    }
}
.writing-desk-about-panel
{
    top: calc(80px + 3em) !important;
    right: 10px;
}
.top-right-about-icon.writing-desk-save-icon {
    color: $lightGreen;
    fill: $midGreen;
    transition: 0.2s;
    cursor: pointer;
    &:hover {
        transform: scale(1.1);
        color: $midPink;
        fill: $midPink;
    }
}
.writing-desk-about-icon
{
    top: 80px !important;
}
.complete-journal-exercise-wrapper
{
    position: absolute;
    right: 1em;
}
//==================== OPERATION PANEL ======================
.diary-operation-panel {
    max-width: 20vw;
    max-height: 89vh;
    transition: visibility 0s linear 0.7s, opacity 0.7s ease-in-out;
    position: absolute;
    right: 20px;
    bottom: 20px;
    z-index: 4;
    display: flex;
    flex-direction: column;
    .operation-btn-ctn {
        max-width: 120px;
        max-height: 120px;
        color: $white;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        // background-color: $darkGreenOpa;
        // border: 1px solid $whiteOpa6;
        background-image: url('../../img/watercolor_icon2.svg');
        background-repeat: no-repeat;
        background-size: contain;
        .operation-btn {
            font-size: 1.2em;
            color: $white;
            @include paragraph;
            @include text-shadow;
            .operation-btn-thumbnail {
                max-width: 50%;
                max-height: 50%;
                margin-left: auto;
                margin-right: auto;
                display: block;
                padding: 0 0 10px 0;
            }
        }
    }
}
.diary-section-row
{
    max-width: 100vw;
    width: 100%;
    overflow-y: hidden;
    padding: 5px;
    padding-left: 20px;
    padding-right: 20px;
    height: fit-content;
    // margin-top: 10px;
}
.diary-section-btn
{
    font-size: 0.8em;
    min-width: 200px;
    background-color: $lightGrey;
    color: $darkGreen;
    border-radius: 20px;
    height: max-content;
    @include bottombox-shadow;
    // padding-top: 5px;
    // padding-bottom: 5px;
    &:nth-child(1)
    {
        margin-right: 20px;
    }
    &.selected
    {
        color: $white;
        background-color: $darkGreen;
    }
}
.accordion
{
    margin-bottom: 10px;
    background-color: #F6F6F6;
}
.accordion-font
{
    font-size: 0.7em;
    font-family: $paragraphFont;
}

// ============================ TOAST ==============================
.toast {
    margin-left: auto;
    margin-right: auto;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 10px;
    width: 500px;
    padding: 10px;
    background-color: $lightGreen;
    border-radius: 10px;
    z-index: 999;
    &.show {
        @include animation(flyUp $gentle 1);
    }
    &.fade {
        @include animation(fadeOut $gentle 1);
    }
}
.narrative-notes-textarea,
.imagined-dialogues-textarea,
.imagined-dialogue-question,
.writing-type-btn,
.writing-subject-select
{
    color: $darkPink;
    font-family: $diaryFont;
    font-size: 0.8em;
    background-color: transparent;
}
.writing-desk-date-wrapper
{
    max-width: 50%;
    padding-left: 10px;
    padding-right: 10px;
    z-index: 9;
}
.lmh-primary-btn
{
    border-radius: 5px;
    &:hover
    {
        background-color: $lighterYellow;
    }
}
.journal-browsing-icon
{
    width: 30px !important;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 9;
    background-color: $lightGreenOpa;
    border-radius: $radius;
    transition: .4s;
    &:hover, &:active
    {
        background-color: $lightYellow;
        @include bottombox-shadow;
        transform: scale(1.1);
    }
}
.imagined-dialogue-text-block
{
    position: relative;
    max-width: calc(100% - 20px) !important;
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    @include bottombox-shadow;
    margin-bottom: 1em;
    mix-blend-mode: normal;
    .imagined-dialogue-question
    {
        font-weight: bolder;
    }
}
.imagined-dialogue-answer
{
    font-size: 0.8em;
}

// =============================== BUILD ACCORDION ============================
.accordion
{
    transition: max-height 1s ease-in-out;
}
.lmh-accord-header, .accordion-button
{
    @include paragraph;
    width: 100%;
    cursor: pointer;
    transition: .4s ease-in-out;
    &:hover
    {
        background-color: $lightYellow;
    }
}
.accordion-header
{
    width: 100%;
    cursor: pointer;
    transition: .4s ease-in-out;
    &:hover
    {
        background-color: $lightYellow;
    }
}
.lmh-accord-body
{
    @include paragraph;
    padding-left: 1em;
    font-size: 0.9em;
}
.accordion-collapse
{
    transition: max-height .4s ease-in-out;
    max-height: 0;
    transform: scaleY(0);
    &.show
    {
        height: auto;
        max-height: 999px;
        transform: scaleY(1);
    }
}

@include media-sm {
    .diaryFont
    {
        font-size: 0.8em;
    }
    .journal-prompts-panel {
        order: 2;
        z-index: 3;
        position: relative;
        background: transparent !important;
        // max-width: 100%;
        // min-width: 100%;
        // padding: 5px !important;
        margin: 0;
        // background-color: transparent;
        &.active {
            height: calc(100% + 80px);
        }
        .journal-section {
            margin-top: 20px;
        }
    }
    .operation-btn-ctn {
        width: 60px;
        height: 60px;
    }
    .toast {
        width: 100%;
    }
    .journal-activity-title-container {
        // width: 100% !important;
        // padding: 0;
        // margin: 0;
    }
    .journal-activity-panel {
        order: 1;
        width: 100%;
        position: relative !important;
        max-width: 100% !important;
        padding: 5px !important;
        // padding-top: 10px;
        // padding-bottom: 10px;
    }
    .memorial-feedback-label {
        width: calc(100% - 20px) !important;
    }
    //==================DIARY WRAPPER===================
    .diary-root-wrapper {
        background: transparent url('../../img/squared_notebook_bg-01.png') repeat 0 0 !important;
        border-radius: 20px;
        height: 100%;
    }
    .diary-wrapper{
        // min-height: 100vh;
        width: 100%;
        display: flex;
        padding: 0;
        margin: 0;
        justify-content: flex-start;
        flex-wrap: wrap;
        flex-direction: column;
    }
}

@include media-md {
    .journal-prompts-panel {
        order: 2;
        z-index: 3;
        position: relative;
        // background-color: $white;
        max-width: 100%;
        min-width: 100%;
        // padding: 5px !important;
        background: transparent !important;
        // margin: 20px 5px 20px 5px !important;

        &.active {
            height: calc(100% + 80px);
        }
        .journal-section {
            margin-top: 20px;
        }
    }
    .operation-btn-ctn {
        width: 60px;
        height: 60px;
    }
    .journal-activity-panel {
        order: 1;
        width: 100%;
        position: relative !important;
        max-width: 100% !important;
        padding: 5px !important;
        margin: 20px 5px 20px 5px !important;

    }
    .memorial-feedback-label {
        width: calc(100% - 20px) !important;
    }
    //==================DIARY WRAPPER===================
    .diary-wrapper{
        background: transparent url('../../img/squared_notebook_bg-01.png') repeat 0 0 !important;
        
        // background-image: url('../img/diary_panel-md.jpg');
        // min-height: 100vh;
        max-width: 100%;
        display: flex;
        justify-content: flex-start;
        padding: 0;
        margin: 0;
    }
}

@include media-lg {
    .journal-prompts-panel {
        z-index: 3;
        position: relative;
        order: 4;
        // background-color: $white;
        // width: 80%;
        max-width: 100%;
        min-width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding: 5px !important;
        background: transparent !important;
        margin: 20px 5px 20px 5px !important;

        &.active {
            height: calc(100% + 80px);
        }
        .journal-section {
            margin-top: 20px;
        }
    }
    .operation-btn-ctn {
        width: 120px;
        height: 120px;
    }
    .journal-activity-title-container {
        width: 100% !important;
        margin: 0;
        // position: absolute;
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        // padding: 20px;
        padding-right: 50%;
        order: 1;
    }
    .diary-section-row
    {
        display: flex;
        justify-content: flex-start;
        order: 2;
    }
    .diary-section-btn
    {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .journal-activity-panel {
        order: 3;
        width: 100%;
        position: relative !important;
        max-width: 100% !important;
        // padding-top: 1em;
        // padding-top: 6em;
        // padding: 10px 10px 10px 10px !important;
        // margin: 20px 10px 20px 5px !important;
    }
    .memorial-feedback-label {
        width: calc(100% - 20px) !important;
    }
    //==================DIARY WRAPPER===================
    .diary-wrapper{
        background: transparent url('../../img/squared_notebook_bg-01.png') repeat 0 0 !important;
        // background-image: url('../img/diary_panel-md.jpg');
        // min-height: 100vh;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        padding: 0;
        margin: 0;
    }
    .diary-operation-panel {
        max-width: 20vw;
        max-height: 89vh;
        transition: visibility 0s linear 0.7s, opacity 0.7s ease-in-out;
        position: absolute;
        right: 20px;
        bottom: 20px;
        z-index: 4;
        display: flex;
        flex-direction: column;
    }
    
}

@include media-xl {
    .journal-prompts-panel {
        order: 2;
        z-index: 3;
        position: absolute;
        // mix-blend-mode: multiply;
        // background: $white;
        background: linear-gradient(90deg, rgba(216,216,216,1) 0%, rgba(255,255,255,1) 10%) !important;
        padding: 20px !important;
        margin: 0px !important;
        width: 50% !important;
        max-width: 50% !important;
        min-width: 50% !important;
        border-top-right-radius: $radius;
        border-bottom-right-radius: $radius;
        min-height: 100%;
        &.active {
            height: 100%;
        }
    }
    .diary-section-row
    {
        display: none;
    }
    .journal-section {
        &.active {
            height: 280px;
        }
    }
    .journal-activity-panel {
        order: 1;
        // display: block;
        background: $white;
        // mix-blend-mode: multiply;
        max-width: 50% !important;
        padding: 0 20px 0 20px;
        margin: 0px !important;
        width: 50% !important;
        // padding-top: 1em;
        max-height: 100%;
        border-top-left-radius: $radius;
        border-bottom-left-radius: $radius;
        overflow-y: scroll;
    }
    //==================DIARY WRAPPER===================
    .diary-root-wrapper {
        width: calc(100vw - 200px);
        // max-height: calc(100vh - 120px);
        margin-left: auto;
        margin-right: auto;
        font-size: 1.2em;
        position: relative;
    }
    .diary-wrapper{
        background: transparent url('../../img/squared_notebook_bg-01.png') repeat 0 0 !important;
        border-radius: 20px;
        max-height: 100%;
        // background-image: url('../img/squared_notebook_bg-01.png');
        // background-repeat: repeat !important;
        // background-size: auto;
    }
}